import React, { useState, useEffect, useCallback, createRoot } from 'react';
import { Form, Button, Toast, Col, Modal } from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import Cropper from 'react-easy-crop'
import domtoimage from 'dom-to-image';
import { TagsInput } from '@mantine/core';
import LocationInput from '../../components/LocationInput';
import { useNavigate } from 'react-router-dom';
import axiosCustom from '../../utilities/axiosCustom';

/*
Future:
[] Add loader in Edit Profile Pic Button - disable while loading, start spinning
[] Switch icon when successfully uploaded to new profile picture
[] Resize profile photo
*/

const profileImageLink = `/api/user/profile-picture/${localStorage.userID}`;

var formGroupStyle = {
    marginBottom: '1rem'
}
var formLabelStyle = {
    fontWeight: '600',
    float: 'left'
}

function EditProfile() {
    const [hasChanged, setHasChanged] = useState(false);
    const [uploadingProfilePicture, setIsUploadingProfilePicture] = useState(false);
    const [showUploadProfileSuccess, setShowUploadProfileSuccess] = useState(false);
    const [accountType, setAccountType] = useState('');

    const [skillCategories, setSkillCategories] = useState([]);
    const [skillCategoryEntries, setSkillCategoryEntries] = useState([]);
    const [locationEntry, setLocationEntry] = useState('');
    const [suggestions, setSuggestions] = useState('');

    const navigate = useNavigate();

    var handleSkillCategoryChange = function(newValue){
        // update in formData as well as skillCategoryEntries
        setSkillCategoryEntries(newValue);
        setHasChanged(true);
        setFormData({
        ...formData,
        'skills': newValue.join(',')
        });
    }

    var isUploadSuccess = function(result){
        if(result){
            setShowUploadProfileSuccess(true);
            setTimeout(function(){
                setShowUploadProfileSuccess(false);
            }, 3000);
        }else{
            setShowUploadProfileFailure(true);
            setTimeout(function(){
                setShowUploadProfileFailure(false);
            }, 3000)
        }
    }

    const [showUploadProfileFailure, setShowUploadProfileFailure] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        isBusiness: false,
        businessName: '',
        headline: '',
        aboutMe: '',
        workExperience: '',
        skills: '',
        languages: '',
        education: '',
        city: '',
        hourlyRate: '',
        accountType: ''
    });

    var handleSubmit = async function(event){
        event.preventDefault();
        axiosCustom.put('/api/user/edit', formData, {
            headers: {
            'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            setHasChanged(false);
            navigate(`/profile`);
        })
        .catch((error) => {
            console.error('Error updating user:', error);
        })
    }

    const [showProfilePhotoCrop, setShowProfilePhotoCrop] = useState(false);

    const [profileImageToCrop, setProfileImageToCrop] = useState(''); // todo: delete
    
    const [profileImageFile, setProfileImageFile] = useState(null);

    var ProfileIcon = function() {
        const onDrop = useCallback(acceptedFiles => {
          const file = acceptedFiles[0];
          setProfileImageFile(file);
          const reader = new FileReader();
          reader.onload = function(e) {
            const blob = new Blob([e.target.result], { type: file.type });
            const imageURL = URL.createObjectURL(blob);
            setProfileImageToCrop(imageURL);
            // const newImage = document.createElement('img');
            // newImage.onerror = function(e){
            //   console.error('newImage error: ', newImage);
            // }
            
            // newImage.src = imageURL;
            
            // newImage.onload = function(){
            // newImage.id = 'profile-image-pre-cropped';

            // store the HTML image in state for the cropper to use
              
            setShowProfilePhotoCrop(true);
            // };
          };
          reader.onerror = function(e) {
            alert("Error: Could not process profile image.")
          }
          reader.readAsArrayBuffer(file);
          // at this point, the crop window has been opened. The next step will be the user submitting the crop, which will be handled by handleSubmitCrop()
        }, []);

        const {getRootProps, getInputProps, open} = useDropzone({onDrop, noClick:true, noDrag:true, accept: {"image/*": [".png", ".gif", ".jpeg", ".jpg"]}, multiple: false, maxSize: 20000000});

        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />        {
                uploadingProfilePicture ? <> <br/> <Button disabled>Uploading...</Button></> : <> <br /><Button onClick={open}>Edit Profile Photo</Button></>
            }
          </div>
        )
    }

    var handleFormChange = function(event){
        if(event.target.name === 'aboutMe'){
          if(event.target.value.length < 501){
            if(!hasChanged){
              setHasChanged(true);
            }
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          }
        }else if(event.target.name === 'isBusiness'){
          setFormData({...formData, isBusiness: event.target.checked})
          setHasChanged(true);
        }else{
          if(!hasChanged){
            setHasChanged(true);
          }
          setFormData({
            ...formData,
            [event.target.name]: event.target.value
          });
        }

    }
  
    var updateSelectedCityId = function(newCityId){
        setFormData({...formData, city: newCityId});
        setHasChanged(true);
    }

    var loadFields = async function(){
        axiosCustom.get('/api/user')
        .then((response) => {
            const data = response.data;

            var dataWithNullsRemoved = {};

            Object.keys(data.userObject).forEach(function(key){
                dataWithNullsRemoved[key] = ((data.userObject[key] === null) ? "" : data.userObject[key]);
            })

            setSkillCategoryEntries( data.userObject['skills']?.length ? data.userObject['skills'].split(',') : []);

            setAccountType(data.userObject.accountType);
            setFormData(dataWithNullsRemoved);

            setLocationEntry(data.userObject.cityName + ', '+ data.userObject.stateId + ', ' + data.userObject.zip);
        })
        .catch((error) => {
            console.error('Error fetching user:', error);
        });
    }
  
    var loadSkillCategories = async function(){
        axiosCustom.get('/api/get-skill-categories')
        .then((response) => {
            setSkillCategories(response.data);
        })
    }

  useEffect(function(){
    loadFields();
    loadSkillCategories();
  }, [])

  // const Output = ({ croppedArea, src }) => {
  //   const scale = 100 / croppedArea.width;
  //   const transform = {
  //     x: `${-croppedArea.x * scale}%`,
  //     y: `${-croppedArea.y * scale}%`,
  //     scale,
  //     width: "calc(100% + 0.5px)",
  //     height: "auto"
  //   };
  
  //   const imageStyle = {
  //     transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
  //     width: transform.width,
  //     height: transform.height
  //   };
  
  //   return (
  //     <div
  //       className="output"
  //       style={{ paddingBottom: `${100 / (1 / 1)}%` }}
  //     >
  //       <img id="profile-picture-cropped" src={src} alt="" style={imageStyle} />
  //     </div>
  //   );
  // };

  var CropImage = function(){
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    
    var handleSubmitCrop = function(){
      var node = new Image();
      node.src = profileImageToCrop;
      node.onload = function(){
        // V2: Upload to server, server uploads to r2
        const formData = new FormData();
        formData.append('image', profileImageFile);
        formData.append('croppedArea', JSON.stringify(croppedAreaPixels));
        formData.append('croppedAreaPixels', JSON.stringify(croppedAreaPixels));

        axiosCustom.post('/api/user/upload-profile-picture', formData, {
          headers: {
            'Content-Type': 'image/*'
          }
        })
        .then((result) => {
          console.log('upload image result: ', result);
          isUploadSuccess(true);            
          setIsUploadingProfilePicture(false);
          setShowProfilePhotoCrop(false);
          window.location.reload();

        })
        .catch((error) => {
          isUploadSuccess(false);
          setIsUploadingProfilePicture(false);
          setShowProfilePhotoCrop(false);
          alert('error: Could not get upload signed url, or upload to s3.')
        })
      }
    }

    // output should NOT be displayed. It should run once, transform an invisible image, and save the image to the server

    return(
      <>
        <Modal animation={false} show={showProfilePhotoCrop} onHide={()=>{setShowProfilePhotoCrop(false)}}>
          <Modal.Header>
            <Modal.Title>Crop Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="crop-container" style={{height:'500px'}}>
          {
            showProfilePhotoCrop ?
              <>
              <Cropper
                image={profileImageToCrop}
                crop={crop}
                onCropChange={setCrop}
                zoom={1}
                // onZoomChange={setZoom}
                aspect={1 / 1}
                cropShape={'rect'}
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  console.log('croppedArea = ', croppedArea);
                  console.log('croppedAreaPixels = ', croppedAreaPixels);
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
              />
              </>
              :
              null
          } 
          </div>
          
          <hr />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowProfilePhotoCrop(false)}}>
              Cancel
            </Button>
            <Button onClick={handleSubmitCrop} variant="primary">
              Save Profile Photo
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Toast style={{position: 'fixed', top: '0', right:'0', zIndex:'999'}} onClose={() => setShowUploadProfileSuccess(false)} show={showUploadProfileSuccess} delay={3000} autohide={true} className="d-inline-block m-1" bg='success' position="top-end">
        <Toast.Body>Successfully added your profile picture!</Toast.Body>
      </Toast>
      
      <Toast style={{position: 'fixed', top: '0', right:'0', zIndex:'999'}} onClose={() => setShowUploadProfileFailure(false)} show={showUploadProfileFailure} delay={3000} autohide={true} className="d-inline-block m-1" bg='danger' position="top-end">
        <Toast.Body>Could not upload your profile photo, please try again later.</Toast.Body>
      </Toast>

      <CropImage />

      <h3>Edit My Profile</h3>
      
        <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            
            <div className="mb-10 mt-10" className="row">
              <Col xs={6}>

                <img loading="lazy" id="profile-photo" src={profileImageLink} width='160' />
                <ProfileIcon />
              </Col>
              <Col xs={6}>
                <Form.Group style={formGroupStyle}>
                  <Form.Label style={formLabelStyle}>First Name</Form.Label>
                  <Form.Control value={formData.firstName} name="firstName" onChange={handleFormChange} type="text" placeholder="First Name" />
                </Form.Group>
                <Form.Group style={formGroupStyle}>
                  <Form.Label style={formLabelStyle}>Last Name</Form.Label>
                  <Form.Control value={formData.lastName} name="lastName" onChange={handleFormChange} type="text" placeholder="Last Name" />
                </Form.Group>
                {
                  formData.isBusiness ?
                  <>
                    <Form.Group style={formGroupStyle}>
                      <Form.Label style={formLabelStyle}>Business Name</Form.Label>
                      <Form.Control value={formData.businessName} name="businessName" onChange={handleFormChange} type="text" placeholder="Business Name" />
                    </Form.Group>
                    <p><small>Your public profile will show your business name.</small></p>
                  </>
                  :
                  ""
                }

                <Form.Group style={formGroupStyle}>
                  {/*<Form.Label style={formLabelStyle}>This is a business profile.</Form.Label>*/}
                  {/*<Form.Control value={formData.isBusiness} name="isBusiness" onChange={handleFormChange} type="text" />*/}
                  <Form.Check type="checkbox" name="isBusiness" label="This is a business profile." onChange={handleFormChange} />
                </Form.Group>
              </Col>
              
            </div>

            <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>About Me</Form.Label>
              <Form.Control value={formData.aboutMe} name="aboutMe" onChange={handleFormChange} as="textarea" rows={4} placeholder="About me"   style={{ whiteSpace: 'pre-wrap' }} />
              <small className="text-body-secondary" style={{float: 'left'}}>{(500-formData.aboutMe.length)} characters remaining</small>
              <br />
              {
                formData.accountType === 'client' ? "" : <small>This is the perfect place to show off your experience! Include any special credentials, licenses, and or certificates you have! What makes you stand out? </small>
              }
            </Form.Group>
            <br />

            {
              formData.accountType === 'client' ? "" : 
              <>
                <b style={{float: 'left'}} >Skills / Job Title</b>
                <br />
                <TagsInput
                  label=""
                  placeholder="Enter Your Skills"
                  data={skillCategories}
                  value={skillCategoryEntries}
                  onChange={handleSkillCategoryChange}
                />
                <small>These are the skills/job titles that will be searchable so that people can find you!</small>
              </>
            }
            
            {
              formData.accountType === 'client' ? "" : 
              <>
                <Form.Group style={formGroupStyle}>
                  <Form.Label style={formLabelStyle}>Education</Form.Label>
                  <Form.Control value={formData.education} name="education" onChange={handleFormChange} as="textarea" rows={3} placeholder="What is your education background?"   style={{ whiteSpace: 'pre-wrap' }} />
                </Form.Group>
              </>
            }
          </div>
          <div className="col-lg-6">
            <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>Headline</Form.Label>
              <Form.Control value={formData.headline} name="headline" onChange={handleFormChange} type="text" placeholder="What is your Perfect Part-Time?" />
            </Form.Group>

            {
              formData.accountType === 'client' ? "" : 
              <>
            <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>Work Experience</Form.Label>
              <Form.Control value={formData.workExperience} name="workExperience" onChange={handleFormChange} as="textarea" rows={3} placeholder="What is your work experience?"   style={{ whiteSpace: 'pre-wrap' }} />
            </Form.Group>
              </>
            }

            <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>Languages</Form.Label>
              <Form.Control value={formData.languages} name="languages" onChange={handleFormChange} type="text" placeholder="What languages do you work in?" />
            </Form.Group>

            {/* <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>Zip Code</Form.Label>
              <Form.Control value={formData.city} name="zipCode" onChange={handleFormChange} type="number" placeholder="What is your zip code?" />
            </Form.Group> */}

            <b style={{float: 'left'}} >Location</b>
            <br />
            <LocationInput updateSelectedCityId={updateSelectedCityId} locationInput={locationEntry} setLocationInput={setLocationEntry} suggestions={suggestions} setSuggestions={setSuggestions}/>
            <small className="text-body-secondary" style={{float: 'left'}}>Tip: Try using your zipcode for the most accurate location. Only your city name will be displayed on your profile.</small>
            {
              formData.accountType === 'client' ? "" : 
              <>
            <Form.Group style={formGroupStyle}>
              <Form.Label style={formLabelStyle}>Hourly Rate</Form.Label>
              <Form.Control value={formData.hourlyRate} name="hourlyRate" onChange={handleFormChange} type="text" placeholder="What is your hourly rate?" />
            </Form.Group>
              </>
            }

            <Form.Group style={formGroupStyle}>
                <label style={formLabelStyle} htmlFor="accountType">Role:</label>
                <select name="accountType" className="form-control" id="accountType" value={formData.accountType} onChange={(event) => {if(event.target.value.length){setFormData({...formData, accountType: event.target.value}); setHasChanged(true); }}} required>
                  <option value="">...</option>
                  <option value="worker">Searching for work.</option>
                  <option value="client">Searching to hire.</option>
                  <option value="both">Searching for work and to hire.</option>
                  <option value="networker">Looking to network.</option>
                </select>
            </Form.Group>

            <Button disabled={!hasChanged} style={{width: "100%"}} size="md" variant="primary" type="submit">Save Changes</Button>
            <br />
            <br />
            <Button onClick={()=>{navigate(`/profile`)}} style={{width: "100%"}} size="md" variant="outline-primary" type="submit">Discard Changes</Button>
            
          </div>
          
        </div>
      </form>

      <div className='viewer' id='profile-image-cropped-container' style={{opacity: '0'}}>
        <div>
          <div className='output' id='profile-image-cropped-container'>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;